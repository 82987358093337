import React from 'react';
import s from './InfoSectionDescription.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import xss from 'xss';

export enum DataHook {
  Description = 'info-section-description',
}
export interface InfoSectionDescriptionProps extends ProvidedGlobalProps {
  description: string;
  index: number;
}

@withGlobalProps
export class InfoSectionDescription extends React.Component<InfoSectionDescriptionProps> {
  public render(): JSX.Element {
    const {description, index, globals} = this.props;

    const sanitizeDescription = (descriptionToSanitize: string): string => {
      const {isInfoSectionDescriptionXssProductPageEnabled} = globals.experiments;

      return isInfoSectionDescriptionXssProductPageEnabled ? xss(descriptionToSanitize) : descriptionToSanitize;
    };

    return (
      <div
        key={index}
        data-hook={DataHook.Description}
        className={s.description}
        dangerouslySetInnerHTML={{__html: sanitizeDescription(description)}}
      />
    );
  }
}
